<template>
		<div>
			<div 
				v-if="$root.investor.loggedIn==true"
			>
				<div v-if="messages==null || messages.count==0"
					class="full center"
				>
					<div v-if="isMessages==true">
						<h1>There are no Messages</h1>
					</div>
					<div v-if="isCampaigns==true">
						<h1>There are no Campaigns</h1>
					</div>
				</div>

				
				<div class="full center"  v-if="isCampaigns==true">
					<router-link
						:to="'/campaigns/' + this.$root.investor.uid + '/'"
					>
						My Campaigns
					</router-link>
					<div
						v-if="$root.hasRole('ma')==true"
						class=tag
					>
						| &nbsp; &nbsp;
						<router-link
							to="/campaigns/all/"
						>
							All Campaigns
						</router-link>
					</div>
					<v-btn
						v-if="$root.investor&&$root.investor.dfAuth==true"
						outlined
						v-on:click='newCampaign($event)'
					>
						New Campaign 
					</v-btn>
					<br><br>	
				</div>

				<div class="all_messages_cont">
					<div 
						class="message_cont"
					>
						<div v-if="isCampaigns==true">
							<div class="campaign_id title">
								ID	
							</div>
							<div class="name title">
								Market
							</div>
							<div class="regarding title">
								Campaign
							</div>
							<div class="intro title">
								Title
							</div>
							<div class="date title">
								Date
							</div>
						</div>
						<div v-if="isMessages==true">
							<div class="name title">
								From
							</div>
							<div class="regarding title">
								Regarding
							</div>
							<div class="intro title">
								Message
							</div>
							<div class="date title">
								Date
							</div>
						</div>
						<div class=actions>
							<v-btn
								:loading="loading"
								outlined
								v-on:click='reload($event)'
							>
									Reload 
							</v-btn>
						</div> 
				
						<hr class="full end">
					</div>
					<div 
						class=scrollable
						v-on:scroll.passive="loadMoreMessages($event)"
					>
					<div
						class="message_cont"
						v-for="message in messages"
						:key="message.id"
						v-bind:class="[message.unread?'unread':'read']"
						v-on:click='showMessage($event, message.id, message.unread)'	
					>
						<div 
							class=campaign_id
							v-if="isCampaigns==true"
						>
							{{ message.id }}
						</div>
						<div 
							class=market
							v-if="isCampaigns==true"
						>
							{{ message.market_name }}
						</div>
						<div 
							class=name
							v-if="isMessages==true"
						>
							{{ message.first_name }} {{ message.last_name }}
						</div>
						<div 
							class=x_campaign
							v-if="isCampaigns==true"
						>
							{{ message.x_campaign }}
						</div>
						<div 
							class=regarding
							v-if="isMessages==true"
						>
							{{ message.regarding }}
						</div>
						<div
							v-if="isCampaigns==true"
						>
							<div 
								class=intro
							>
								{{ message.title }}
							</div>
						</div>
						<div
							v-if="isMessages==true"
						>
							<div 
								class="intro no_message"
								v-if="message.message==null || message.message.length==0"
							>
								No Message
							</div>	
							<div 
								class=intro
								v-else
							>
								{{ message.message }}
							</div>
						</div>
						<div 
							v-if="isCampaigns==true"
							class=date
						>
							{{ getReadableDate(message.created_ts) }}
						</div>	
						<div 
							v-if="isMessages==true"
							class=date
						>
							{{ message.ts }}
						</div>	
						<div 
							class=actions
						>
							<v-icon
								v-if="$root.investor.dfAuth==true"
								v-on:click="deleteMessage($event, message.id)"	
							>
								mdi-delete
							</v-icon>
							<v-icon
								v-if="isCampaigns==true&&$root.investor.dfAuth==true"
								v-on:click="editMessage($event, message.id)"	
							>
								mdi-border-color
							</v-icon>
						</div>	
						<hr class="full end">
					</div>
					</div>
				</div>
				<div class="details_cont blue-grey lighten-5">
					<div 
						v-if="isCampaigns==true"
						class=header
					>
						<div v-if="details.title">
							{{ details.title }}
						</div>
						<div v-if="details.regarding">
							{{ details.regarding }}
						</div>
						<div v-if="details.first_name">
							Created By {{ details.first_name }} {{ details.last_name }}
						</div>
						<div v-if="details.from_addr">
							From {{ details.from_addr }}
						</div>
						<div v-if="details.reply_addr">
							Reply To {{ details.reply_addr }}
						</div>
						<div v-if="details.market_name">
							To {{ details.market_name }}	
						</div>
						<div v-if="details.created_ts">
							{{ getReadableDate(details.created_ts) }}
						</div>
						<div v-if="details.is_html!=null">
							Is HTML: {{ details.is_html }}
						</div>
						<div v-if="details.read_receipt!=null">
							Read Receipt: {{ details.read_receipt }}
						</div>
		
					</div>
					<div 
						v-if="isMessages==true"
						class=header
					>
						<div>
							{{ details.first_name }} {{ details.last_name }}
						</div>
						<div>
							{{ details.company }}
						</div>
						<div>
							{{ details.regarding }}
						</div>
						<div v-if="Object.keys(details).length>0">
							Received {{ details.ts }}
						</div>
						<div v-if="Object.keys(details).length>0">
							Read {{ details.read_ts }}
						</div>
						<div v-if="Object.keys(details).length>0">
							Phone {{ details.phone }}
						</div>
						<div>
							<a 
								:href="'mailto:'+details.email"
								target="_blank"
							>
								{{ details.email }}
							</a>
						</div>
					</div>
					<div 
						v-if="details.message && details.message.length>0"
						class="message blue-grey lighten-4"
					>
						<span
							v-html="details.message"	
						>
						</span>
					</div> 
					<div
						v-if="logs.length>0"
					>
						Campaign History
					</div>	
					<div
						v-for="log in logs"
						:key="log.id"
					>
						<v-icon v-if="log.ts_start==0||log.ts_finish==0"> mdi-email-alert-outline </v-icon>
						<v-icon v-else > mdi-email-outline </v-icon>
						Sent <b>{{ getReadableDate(log.ts_start) }}</b> by <b>{{ log.sender_id }}</b> to <b>{{ log.market_name }}</b> as <b>{{ log.campaign_name }}</b>.
					</div>
				</div>
				<br>
			</div>
                        <div
                                class="center"
				v-else
                        >
                                <router-link
                                        to="/"
                                >
                                        Home
                                </router-link>
                                <h1> You Have To Be Logged In To View This Page </h1>
                        </div>

		</div>
</template>

<script>
module.exports = {
	data() {
		return {
			logs: [],
			isMessages: false,
			isCampains: false,
			next: 0,
			size: 25,
			loading: false,
			details: {},
			messages: [],
			titleFontStyle: {},
			titleFontStyleMobile: {},
			textFontStyle: {},
			textFontStyleMobile: {},
		};
	},
	methods: {
		updateLogs() {
			var headers = {
				'Content-Type': 'application/json;charset=utf-8'
			};

			headers['USERNAME'] = this.$root.investor.username;
			headers['PASSWORD'] = this.$root.investor.password;
			headers['SESSION'] = this.$root.investor.sessionKey;

			var ep = "/svc/campaign/logs/"+this.details.campaign_id+"/"

			this.$http.get(ep, { headers }).then(response => {
				this.logs = response.data.logs
			})
		},

		newCampaign() {
			var headers = {
				'Content-Type': 'application/json;charset=utf-8'
			};

			try {
				headers['USERNAME'] = this.$root.investor.username;
				headers['PASSWORD'] = this.$root.investor.password;
				headers['SESSION'] = this.$root.investor.sessionKey;

				var ep = "/svc/campaign/"

				this.$http.put(ep, {}, {headers}).then(response => {
					if(response.data.code==200) {
						var e = '/editCampaign/'+response.data.campaign_id+'/';
						this.go(e)
					} else {
						alert("Unable to save:  " + response.data.description);
					}
				})
			} catch(e) {
				console.log(e);
			}
		},

		loadMoreMessages(event) {
			if(this.isCampaigns==true) {
				return;
			}

			var div = event.target||event.srcElement;
			var closeToBottom = (div.clientHeight+div.scrollTop-div.scrollHeight+20)>=0;

			// Reload if we're close to bottom
			if(closeToBottom&&this.loading==false) {
				this.loading=true
				var self = this
				this.loadMessages(
					function(messages) {
						if(messages&&messages.length>0) {
							self.messages = self.messages.concat(messages);
						}
						console.log("Number of messages: " + self.messages.length)
						self.loading=false;
					}
				)
			}
		},
		reload(e) {
			this.next=0;
			this.loading = true;
			var self = this
			this.loadMessages(	
				function(messages) {
					self.messages = messages;
					self.loading=false;
				}
			)
		},
		editMessage(e, id) {
			this.go("/editCampaign/"+id+"/");
		},
		deleteMessage(e, id) {

			if(confirm("Are you sure you want to delete ID " + id + "?")==false) {
				return;
			}

			var headers = {
				'Content-Type': 'application/json;charset=utf-8'
			};

			headers['USERNAME'] = this.$root.investor.username;
			headers['PASSWORD'] = this.$root.investor.password;
			headers['SESSION'] = this.$root.investor.sessionKey;

			var ep;
			if(this.isCampaigns==true) {
				ep = "/svc/campaign/"+id+"/"
			} else {
				ep = "/svc/contact/"+id+"/"
			}

			this.$http.delete(ep, {headers}).then(response => {
				if(response.data.code==200) {
					var self = this
					this.reload()
				} else {
					alert(response.data.description)
				}
			})
		},
		showMessage(e, id, unread) {
			var row = event.target||event.srcElement;

			console.log("Element clicked: " + row.id)

			// icons have their own actions
			if(row.classList.contains("v-icon")) {
				return;
			}

			// If the row was selected we want all siblings
			if(row.classList.contains("message_cont")==false) {
				row = row.parentElement
			}

			// First unhilight previously highhlighted
			for(var r in row.parentElement.children) {
				try {
					row.parentElement.children[r].classList.remove("blue-grey")
					row.parentElement.children[r].classList.remove("lighten-5")
				} catch(e) {
					console.log(e)
				}
			}

			row.classList.add("blue-grey")
			row.classList.add("lighten-5")

			// Get details
			var headers = {
				'Content-Type': 'application/json;charset=utf-8'
			};

			try {
				this.logs = [];

				headers['USERNAME'] = this.$root.investor.username;
				headers['PASSWORD'] = this.$root.investor.password;
				headers['SESSION'] = this.$root.investor.sessionKey;

				// Get message to display 
				var ep;
				if(this.isMessages) {
					ep = "/svc/contact/"+id+"/"
				} else if(this.isCampaigns) {
					ep = "/svc/campaign/"+id+"/"
				}

				this.$http.get(ep, {headers}).then(response => {
					this.details = response.data.messageList[0]
					try {
						if(this.isMessages) {
							this.details.message = this.b64_to_utf8(this.details.message)
						} else if(this.isCampaigns) {
							this.details.message = this.b64_to_utf8(this.details.body)
							this.updateLogs();
						}
					} catch(e) {
						console.log(e)
					}
				})

				// if we're successfull, mark as read
				if(this.isMessages&&unread) {
					ep = "/svc/contact/read/"+id+"/"
					this.$http.put(ep, {}, {headers}).then(response => {
						if(response.data.code==200) {
							var self = this
							this.reload();
							this.$root.updateNewMessages();
						}
					})
				}


			} catch(e) {
				console.log(e)
			}
		},
		go(href) {
			console.log("going to " + href)
			window.location.href=href
			//this.$router.push({name:href})
		},
		getReadableDate(ts) {
			console.log(ts);
			var t = new Date(ts*1000);
			//var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
			var options = { year: 'numeric', month: 'long', day: 'numeric' };
			var times = t.toLocaleDateString("en-US", options);
			console.log(times);
			return(times);
		},
		b64_to_utf8( str ) {
			return decodeURIComponent(escape(window.atob( str )));
		},
		loadMessages(callback) {
			var headers = {
				'Content-Type': 'application/json;charset=utf-8'
			};

			try {
				headers['USERNAME'] = this.$root.investor.username;
				headers['PASSWORD'] = this.$root.investor.password;
				headers['SESSION'] = this.$root.investor.sessionKey;

				// Get first 10 messages
				var ep;
				if(this.isMessages) {
					ep  = "/svc/contact/list/"+this.next+"/"+this.size+"/"
				} else if(this.isCampaigns) {
					if(this.$route.path.includes("all")) {
						ep  = "/svc"+this.$route.path.replace("all/","");
					} else {
						ep  = "/svc"+this.$route.path.replace("campaigns","campaigner")
					}
				}

				this.$http.get(ep, {headers}).then(response => {
					var nret = 0;
					if(response.data.messageList) {
						nret = response.data.messageList.length;
					}
					console.log("nret is " + nret)
					this.next += nret;
					console.log("this.next is " + this.next)
					callback(response.data.messageList)
				})
			} catch(e) {
				console.log(e);
			}
		}
	},
	updated() {
		console.log("updated");
	},
	activated() {
		console.log("activated");
	},
	unmounted() {
		console.log("unmounted");
	},
	mounted() {

		if(this.$root.investor.loggedIn==false) {
			this.go("/");
		}
	
		var self = this

		if(this.$route.path.includes("messages")) {
			this.isMessages=true;
		} else if(this.$route.path.includes("campaigns")) {
			this.isCampaigns=true;
		}

		this.loadMessages(
			function(messages) {
				self.messages = messages;
			}
		);
	}

};
</script>

<style scoped>

.tag {
	display: inline-block;
	padding: 2px;
	border-width: 2px;
	margin: 3px;
}

.scrollable {
	width: 100%;
	overflow: scroll;
	height: 100%;
}

.title {
	font-size: 20px;
}

.header {
	padding: 15px;
}
.message {
	width: 100%;
	height: 100%;
	padding: 15px 15px 100px 15px;
	overflow: scroll;
}
.no_message {
	font-style: italic;
}

.message_cont {
	max-width: 1200px;
	height: 30px;
	margin: 0 auto;
	align-items: center;
	justify-content: center;
}

.full {
	clear: both;
}

.end {
	margin: 20px 20px 20px 20px;
}

.campaign_id {
	cursor: pointer;
	display: inline;
	width: 5%;
	float: left;
}
.market {
	cursor: pointer;
	display: inline;
	width: 15%;
	float: left;
}
.name {
	cursor: pointer;
	display: inline;
	width: 25%;
	float: left;
}
.x_campaign {
	cursor: pointer;
	display: inline;
	width: 15%;
	float: left;
	min-width: 200px;
}
.regarding {
	cursor: pointer;
	display: inline;
	width: 10%;
	float: left;
}
.intro {
	cursor: pointer;
	display: inline;
	width: 30%;
	float: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.date {
	cursor: pointer;
	display: inline;
	width: 15%;
	float: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.actions {
	display: inline;
	width: 5%;
	min-width: 60px;
	float: left;
}
.all_messages_cont {
	float: left;
	cursor: pointer;
	display: inline;
	width: 50%;
	height: 600px;
}
.details_cont {
	float: right;
	display: inline;
	width: 50%;
}

.mobile {
	margin: 0px 10px 0px 10px;
}

.unread {
	font-weight: bold;
}

.read {
	font-weight: lighter;
}

</style>


<style>
blockquote {
	padding: 0 1em;
	border-left: 3px solid rgba(0,0,0,.05);
	margin: 0 0 1.41575em 3em;
	font-style: italic;
}
hr {
	width:80%;
	text-align:left;
	margin-left:0;
	color: rgba(0,0,0,0.05);
}
ol, ul {
	margin: 0 0 1.41575em 3em;
	padding: 0;
}
</style>
